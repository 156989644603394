import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};
window.availableGraphs.stackedHorizontalBar = function(plotRef, data, leftMargin = 80) {
  // set the dimensions and margins of the graph
  var barHeight = 25;
  var barPadding = 0.15;
  var margin = { top: 20, right: 10, bottom: 20, left: leftMargin };
  var height = (data.length * barHeight * (1 + barPadding)) + margin.top + margin.bottom;
  var width = 600;
  var innerWidth = width - margin.left - margin.right;

  var series = d3.stack()
    .keys(["base", "gain", "loss"])(data)
    .map(d => (d.forEach(v => v.key = d.key), d))

  var x = d3.scaleLinear()
    .domain([0, d3.max(series, d => d3.max(d, d => d[1]))])
    .range([0, innerWidth])

  var y = d3.scaleBand()
    .domain(data.map(d => d.name))
    .range([margin.top, height - margin.bottom])
    .padding(barPadding)

  var color = d3.scaleOrdinal()
    .domain(["base", "gain", "loss"])
    .range([graphColors.value, graphColors.gain, graphColors.loss])

  var stroke = d3.scaleOrdinal()
    .domain(["base", "gain", "loss"])
    .range([graphColors.value, graphColors.gain, graphColors.value])

  var xAxis = g => g
    .style("font", "9px soehne")
    .attr("transform", `translate(${margin.left},${margin.top})`)
    .call(d3.axisTop(x).ticks(4).tickSize(-(height - margin.top - margin.bottom)).tickFormat((d, i) => i === 0 ? "" : d3.format("$,.2s")(d)))
    .call(g => g.selectAll(".tick line").attr("stroke", graphColors.tick))
    .call(g => g.select(".domain").remove())

  var yAxis = g => g
    .style("font", "9px soehne")
    .attr("transform", `translate(${margin.left},0)`)
    .call(d3.axisLeft(y).tickSizeOuter(0))

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .call(xAxis)

  svg.append("g")
    .call(yAxis)

  svg.append("g")
    .selectAll("g")
    .data(series)
    .join("g")
    .attr("fill", d => color(d.key))
    .selectAll("rect")
    .data(d => d)
    .join("rect")
    .attr("x", d => margin.left + x(d[0]))
    .attr("y", (d, i) => y(d.data.name))
    .attr("width", d => x(d[1]) - x(d[0]))
    .attr("height", y.bandwidth())
    .attr("stroke", d => stroke(d.key))
    .append("title")
    .text(d => `${d.data.name} ${d.key.toProperCase()}: ${d3.format("$,.0f")(d.data[d.key])}`)

  svg.append("g")
    .attr("fill", "white")
    .attr("text-anchor", "end")
    .style("font", "9px soehne")
    .selectAll("text")
    .data(data)
    .join("text")
    .attr("x", d => margin.left + x(d.value))
    .attr("y", d => y(d.name) + y.bandwidth() / 2)
    .attr("dy", "0.35em")
    .attr("dx", -4)
    .text(d => d3.format("$,.0f")(d.value))
    .call(text => text.filter(d => x(d.value + d.gain) < (innerWidth / 10)) // short bars
      .attr("x", d => margin.left + x(d.value + d.loss))
      .attr("dx", +4)
      .attr("fill", graphColors.text)
      .attr("text-anchor", "start"))
};