import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.lineWithBubbles = function (plotRef, data) {

  // set the dimensions and margins of the graph
  var margin = ({ top: 30, right: 10, bottom: 30, left: 30 });
  var height = 200 + margin.top + margin.bottom;
  var width = 600 - margin.left - margin.right; //Math.ceil(data.length * barWidth * (1 + barPadding)) - margin.left - margin.right;

  var x = d3.scaleLinear()
    .domain([d3.min(data, d => d.id), d3.max(data, d => d.id)])
    .range([margin.left, width - margin.right])

  var y = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.value * 1.1)])
    .range([height - margin.bottom, margin.top])

  let xAxisLabels = [data[0].name, data[data.length-1].name]
  let xAxisTicks = [data[0].id, data[data.length-1].id]

  var xAxis = g => g
    .style("font", "8px soehne")
    .attr("transform", `translate(0,${height - margin.bottom})`)
    .call(d3.axisBottom(x).tickValues(xAxisTicks).ticks(xAxisLabels.length).tickFormat((d, i) => xAxisLabels[i]).tickSizeOuter(0))
    .call(g => g.selectAll(".tick text").attr("text-anchor", (d, i) => i==0 ? "start" : "end"))
    .call(g => g.selectAll(".tick line").remove())

  var yAxis = g => g
    .style("font", "8px soehne")
    .attr("transform", `translate(${margin.left},0)`)
    .call(d3.axisLeft(y).ticks(5).tickSize(-(width - margin.left - margin.right)).tickFormat(d => d < 10 ? d3.format("$,.2f")(d) : d3.format("$,.2s")(d)))
    .call(g => g.selectAll(".tick line").attr("stroke", graphColors.tick))
    .call(g => g.selectAll(".domain").remove())

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .call(yAxis)

  svg.append("g")
    .call(xAxis)

  var line = d3.line()
    .defined(d => !isNaN(d.value))
    .x(d => x(d.id))
    .y(d => y(d.value))

  function radius(area) {
    return Math.sqrt(area / Math.PI)
  }
  
  // area of 100 = $100,000
  var bubble = d3.scaleLinear()
    .domain([0, 100000])
    .range([0, 100])

  svg.append('g')
    .selectAll("dot")
    .data(data)
    .enter()
    .append("circle")
      .attr("cx", d => x(d.id))
      .attr("cy", d => y(d.value))
      .attr("r", d => radius(bubble(Math.abs(d.area)))) 
      .style("fill", d => d.area > 0 ? graphColors.positive : graphColors.negative)
  
  svg.append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", graphColors.target)
    .attr("stroke-width", 2.5)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("d", line);

};