import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.barWithNegatives = function (plotRef, data) {
  // set the dimensions and margins of the graph
  var margin = ({ top: 0, right: 0, bottom: 0, left: 0 });
  var height = 100;
  var width = 600 - margin.left - margin.right;

  var x = d3.scaleBand()
    .domain(d3.range(data.length))
    .range([margin.left, width - margin.right])
    .padding(0.1)

  // Adjust the domain of the y scale to include negative values
  var y = d3.scaleLinear()
    .domain([d3.min(data, d => d.delta), d3.max(data, d => d.delta)]).nice()
    .range([height - margin.bottom , margin.top])

  var xAxis = g => g
    .attr("transform", `translate(0,${y(0)})`)
    .call(d3.axisBottom(x).tickValues([]).tickSizeOuter(0))

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", (d, i) => x(i))
    .attr("y", d => y(Math.max(0, d.delta))) // Adjust y position for negative values
    .attr("height", d => Math.abs(y(d.delta) - y(0))) // Adjust height for negative values
    .attr("width", x.bandwidth())
    .attr("fill", d =>
      d.delta < 0 ? graphColors.negative : graphColors.positive
    )
    .append("title")
    .text(d => `${d.name}: ${d3.format("$,.0f")(d.delta)}`);

  svg.append("g").call(xAxis);
};