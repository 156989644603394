import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.donut = function (plotRef, data) {

  color = d3.scaleOrdinal()
    .domain(data.map(d => d.name))
    .range([graphColors.donutTint1, graphColors.donutTint2, graphColors.donutTint3, graphColors.donutTint4])

  height = 400;
  width = 400;

  const radius = Math.min(width, height) / 2;

  arc = d3.arc().innerRadius(radius * 0.35).outerRadius(radius - 1);

  pie = d3.pie()
    .padAngle(0.005)
    .sort(null)
    .value(d => d.value)
    
  const arcs = pie(data);

  const svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [-width / 2, -height / 2, width, height]);

  svg.selectAll("path")
    .data(arcs)
    .join("path")
    .attr("fill", d => color(d.data.name))
    .attr("d", arc)
    .append("title")
    .text(d => `${d.data.name}: ${d3.format("$,.0f")(d.data.value)}`);

  svg.append("g")
    .attr("fill", "white")
    .attr("font-family", "soehne")
    .attr("font-size", 18)
    .attr("text-anchor", "middle")
    .selectAll("text")
    .data(arcs)
    .join("text")
    .attr("transform", d => `translate(${arc.centroid(d)})`)
    .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.20)
      .append("tspan")
      .attr("y", "-0.35em")
      .text(d => d.data.name)
      .append("tspan")
      .attr("x", 0)
      .attr("y", "0.7em")
      .attr("fill-opacity", 0.7)
      .text(d => `${d3.format("$,.0f")(d.data.value)}`));

};