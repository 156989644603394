import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.barWithCAGR = function (plotRef, data) {

  // set the dimensions and margins of the graph
  var barPadding = 0.1;
  var margin = ({ top: 10, right: 40, bottom: 30, left: 40 });
  var height = 300 + margin.top + margin.bottom;
  var width = 600 - margin.left - margin.right;
  
  var series = d3.stack()
    .keys(['balance','delta','cagr'])(data)
    .map(d => (d.forEach(v => v.key = d.key), d))

  var x = d3.scaleBand()
    .domain(data.map(d => d.name))
    .range([margin.left, width - margin.right])
    .padding(barPadding)

  var extremeDelta = Math.max(-d3.min(data, d => d.delta), d3.max(data, d => d.delta))
  var extremeCAGR  = Math.max(-d3.min(data, d => d.cagr), d3.max(data, d => d.cagr))

  var terminal = data[data.length - 1]

  var yLeft = d3.scaleLinear()
    .domain([0, extremeDelta]).nice()
    .range([((height - margin.top - margin.bottom)/2) + margin.top, margin.top])

  var yRight = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.cagr)])
    .range([((height - margin.top - margin.bottom) / 2) + margin.top, margin.top])
  
  var yLeftScale = d3.scaleLinear()
    .domain([-extremeDelta, extremeDelta])
    .range([height - margin.bottom, margin.top]);
  var yRightScale = d3.scaleLinear()
    .domain([-extremeCAGR, extremeCAGR])
    .range([height - margin.bottom, margin.top]);

  var xAxis = g => g
    .attr("transform", `translate(0,${((height - margin.top - margin.bottom) / 2) + margin.top})`)
    .call(d3.axisBottom(x).tickValues([]).tickSizeOuter(0))

  var xAxisLabels = g => g
    .attr("transform", `translate(0,${height - margin.bottom})`)
    .call(d3.axisBottom(x).tickSize(0).tickFormat(d => { return d.startsWith("March") ? "FY" + d.slice(d.length - 2) : " " }).tickSizeOuter(0))
    .call(g => g.selectAll(".tick line").remove())
    .call(g => g.selectAll(".domain").remove())

  var yLeftAxis = g => g
    .attr("transform", `translate(${margin.left},0)`)
    .call(d3.axisLeft(yLeftScale).ticks(8).tickSize(-(width - margin.left - margin.right)).tickFormat(d => d3.format("$,.2s")(d)))
    .call(g => g.selectAll(".tick line").attr("stroke", graphColors.tick))
    .call(g => g.selectAll(".domain").remove())

  var yRightAxis = g => g
    .attr("transform", `translate(${width - margin.right},0)`)
    .call(d3.axisRight(yRightScale).ticks(8).tickSize(-(width - margin.left - margin.right)).tickFormat(d => d3.format(".0f")(d * 100) + '%'))
    .call(g => g.selectAll(".tick line").remove())
    .call(g => g.selectAll(".domain").remove())

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .call(yLeftAxis)

  // svg.append("g")
  //   .call(yRightAxis)

  svg.append("g")
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", (d, i) => x(d.name))
    .attr("y", d => (d.delta < 0) ? yLeft(0) : yLeft(d.delta))
    .attr("height", d => Math.abs(yLeft(0) - yLeft(d.delta)))
    .attr("width", x.bandwidth())
    .attr("fill", d => (d.delta < 0) ? graphColors.negative : graphColors.positive)
    .append("title")
    .text(d => `${d.name}: ${d3.format("$,.0f")(d.delta)}`)

  var line = d3.line()
    .defined(d => (d.cagr != null))
    .x(d => x(d.name) + (x.bandwidth() / 2))
    .y(d => yRightScale(d.cagr))
    
  svg.append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", graphColors.target)
    .attr("stroke-width", 2)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("d", line)
    // .append("title")
    // .text(d => `${d.name}: ${d3.format("0.2%")(d.cagr)}`)

  svg.append("g")
    .attr("font-size", "10")
    .append("text")
    .attr("fill", graphColors.target)
    .attr("x", width - (margin.right / 2))
    .attr("y", yRightScale(terminal.cagr) + 8)
    .attr("text-anchor", "middle")
    .text(`${d3.format("0.2%")(terminal.cagr)}`)

  svg.append("g")
    .attr("font-size", "10")
    .append("text")
    .attr("fill", graphColors.target)
    .attr("x", width - (margin.right / 2)) //x(terminal.name) + (x.bandwidth() / 2)
    .attr("y", yRightScale(terminal.cagr) - 2)
    .attr("text-anchor", "middle")
    .text(`CAGR:`)

  svg.append("g")
    .call(xAxis)
  svg.append("g")
    .call(xAxisLabels)

};