import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.barWithTarget = function (plotRef, data) {

  // set the dimensions and margins of the graph
  // var barWidth = 20;
  var barPadding = 0.2;
  var margin = ({ top: 30, right: 10, bottom: 30, left: 30 });
  var height = 200 + margin.top + margin.bottom;
  var width = 600 - margin.left - margin.right; //Math.ceil(data.length * barWidth * (1 + barPadding)) - margin.left - margin.right;

  var x = d3.scaleBand()
    .domain(data.map(d => d.name))
    .range([margin.left, width - margin.right])
    .padding(barPadding)

  var y = d3.scaleLinear()
    .domain([0, d3.max(data, d => Math.max(d.value, d.target))])
    .range([height - margin.bottom, margin.top])

  var xAxis = g => g
    .style("font", "8px soehne")
    .attr("transform", `translate(0,${height - margin.bottom})`)
    .call(d3.axisBottom(x).tickSize(5).tickFormat((d, i) => { return (i == (data.length - 1)) || (i == 0) ? d : " " }).tickSizeOuter(0))
    .call(g => g.selectAll(".tick line").remove())

  var yAxis = g => g
    .style("font", "8px soehne")
    .attr("transform", `translate(${margin.left},0)`)
    .call(d3.axisLeft(y).ticks(4).tickSize(-(width - margin.left - margin.right)).tickFormat(d => d3.format(",.0%")(d)))
    .call(g => g.selectAll(".tick line").attr("stroke", graphColors.tick))
    .call(g => g.selectAll(".domain").remove())

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .call(yAxis)

  svg.append("g")
    .call(xAxis)

  var line = d3.line()
    .defined(d => !isNaN(d.value))
    .x(d => x(d.name) + (x.bandwidth() / 2))
    .y(d => y(d.target))

  svg.append("g")
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", (d, i) => x(d.name))
    .attr("y", d => y(d.value))
    .attr("height", d => y(0) - y(d.value))
    .attr("width", x.bandwidth())
    .attr("fill", graphColors.value)
    .append("title")
    .text(d => `${d.name}: ${d3.format(",.0%")(d.value)}`)
  
  svg.append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", graphColors.target)
    .attr("stroke-width", 2.5)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("d", line);

};