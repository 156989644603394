import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.horizontalBar = function (plotRef, data, leftMargin = 80) {

  // set the dimensions and margins of the graph
  var barHeight = 25;
  var barPadding = 0.15;
  var margin = { top: 20, right: 10, bottom: 0, left: leftMargin };
  var height = (data.length * barHeight * (1 + barPadding)) + margin.top + margin.bottom;
  var width = 600; // Keep this constant
  var innerWidth = width - margin.left - margin.right;

  var x = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.value)])
    .range([0, innerWidth]);

  var y = d3.scaleBand()
    .domain(d3.range(data.length))
    .rangeRound([margin.top, height - margin.bottom])
    .padding(barPadding)

  var xAxis = g => g
    .style("font", "9px soehne")
    .attr("transform", `translate(${margin.left},${margin.top})`)
    .call(d3.axisTop(x).ticks(4).tickSize(-(height - margin.top - margin.bottom)).tickFormat((d, i) => i === 0 ? "" : d3.format("$,.2s")(d)))
    .call(g => g.selectAll(".tick line").attr("stroke", graphColors.tick))
    .call(g => g.select(".domain").remove())

  var yAxis = g => g
    .style("font", "9px soehne")
    .attr("transform", `translate(${margin.left},0)`)
    .call(d3.axisLeft(y).tickFormat(i => data[i].name).tickSizeOuter(0))

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .call(xAxis);

  svg.append("g")
    .call(yAxis);

  svg.append("g")
    .attr("fill", graphColors.value)
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", margin.left)
    .attr("y", (d, i) => y(i))
    .attr("width", d => x(d.value))
    .attr("height", y.bandwidth())
    .attr("stroke", graphColors.value)
    .style("cursor", "pointer")
    .on("click", (d, i) => navigateTo(i.id));

  svg.append("g")
    .attr("fill", "white")
    .attr("text-anchor", "end")
    .style("font", "9px soehne")
    .selectAll("text")
    .data(data)
    .join("text")
    .attr("x", d => margin.left + x(d.value))
    .attr("y", (d, i) => y(i) + y.bandwidth() / 2)
    .attr("dy", "0.35em")
    .attr("dx", -4)
    .style("cursor", "pointer")
    .on("click", (d, i) => navigateTo(i.id))
    .text(d => d3.format("$,.0f")(d.value))
    .call(text => text.filter(d => x(d.value) < (innerWidth / 10)) // short bars
      .attr("dx", +4)
      .attr("fill", graphColors.text)
      .attr("text-anchor", "start"));

};