import "./graphs/stackedBar"
import "./graphs/groupedBar"
import "./graphs/barWithCAGR"
import "./graphs/barWithNegatives"
import "./graphs/barWithTarget"
import "./graphs/horizontalBar"
import "./graphs/line"
import "./graphs/lineWithComparison"
import "./graphs/lineWithBubbles"
import "./graphs/groupedHorizontalBar"
import "./graphs/stackedHorizontalBar"
import "./graphs/donut"

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

document.addEventListener("turbo:load", function () {
  document.querySelectorAll("[data-graph]:not([data-is-loaded])").forEach((graphEl) => {
    let graphType = graphEl.dataset.graph;
    let dataEl = document.querySelector(graphEl.dataset.source);
    let data = JSON.parse(dataEl.innerText);

    graphEl.dataset.isLoaded = true;
    
    let graphArgs = [graphEl, data];
    
    if (graphEl.dataset.leftMargin) {
      let leftMargin = parseInt(graphEl.dataset.leftMargin);
      graphArgs.push(leftMargin);
    }
    
    window.availableGraphs[graphType](...graphArgs);
    
  })
});