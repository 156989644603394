import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.stackedBar = function (plotRef, data) {

  // set the dimensions and margins of the graph
  // var barWidth = 20;
  var barPadding = 0.1;
  var margin = ({ top: 10, right: 10, bottom: 30, left: 40 });
  var height = 300 + margin.top + margin.bottom;
  var width = 600 - margin.left - margin.right; //Math.ceil(data.length * barWidth * (1 + barPadding)) - margin.left - margin.right;

  var series = d3.stack()
    .keys(data.legend)(data.data)
    .map(d => (d.forEach(v => v.key = d.key), d))

  var x = d3.scaleBand()
    .domain(data.data.map(d => d.name))
    .range([margin.left, width - margin.right])
    .padding(barPadding)

  var y = d3.scaleLinear()
    .domain([0, d3.max(series, d => d3.max(d, d => d[1]))])
    .range([height - margin.bottom, margin.top])

  var color = d3.scaleOrdinal()
    .domain(data.legend)
    .range([graphColors.base, graphColors.baseTint1, graphColors.baseTint2, graphColors.baseTint3])

  var xAxis = g => g
    .attr("transform", `translate(0,${height - margin.bottom})`)
    .call(d3.axisBottom(x).tickSize(5).tickFormat(d => { return d.startsWith("March") ? "FY" + d.slice(d.length - 2) : " " }).tickSizeOuter(0))
    .call(g => g.selectAll(".tick line").remove())

  var yAxis = g => g
    .attr("transform", `translate(${margin.left},0)`)
    .call(d3.axisLeft(y).ticks(8).tickSize(-(width - margin.left - margin.right)).tickFormat((d, i) => i === 0 ? "" : d3.format("$,.2s")(d)))
    .call(g => g.selectAll(".tick line").attr("stroke", graphColors.tick))
    .call(g => g.selectAll(".domain").remove())

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .call(yAxis)

  svg.append("g")
    .call(xAxis)

  svg.append("g")
    .selectAll("g")
    .data(series)
    .join("g")
    .attr("fill", d => color(d.key))
    .selectAll("rect")
    .data(d => d)
    .join("rect")
    .attr("x", (d, i) => x(d.data.name))
    .attr("y", d => y(d[1]))
    .attr("width", x.bandwidth())
    .attr("height", d => y(d[0]) - y(d[1]))
    .append("title")
    .text(d => `${d.data.name} ${d.key.toProperCase()}: ${d3.format("$,.0f")(d.data[d.key])}`)

};