import * as d3 from "d3"
import graphColors from "../graphColors"

if (!window.availableGraphs) window.availableGraphs = {};

window.availableGraphs.groupedBar = function (plotRef, data) {

  // set the dimensions and margins of the graph
  var barPadding = 0.2;
  var margin = ({ top: 30, right: 10, bottom: 60, left: 30 });
  var height = 200 + margin.top + margin.bottom;
  var width = 600 - margin.left - margin.right; 

  const months = data.map(d => d.month);
  const seriesNames = Object.keys(data[0]).slice(1); // exclude 'month'

  const x0 = d3.scaleBand()
    .domain(months)
    .range([margin.left, width - margin.right])
    .paddingInner(barPadding);

  const x1 = d3.scaleBand()
    .domain(seriesNames)
    .rangeRound([0, x0.bandwidth()])
    .padding(barPadding / 2);

  const y = d3.scaleLinear()
    .domain([0, d3.max(data, d => d3.max(seriesNames, s => d[s]))])
    .range([height - margin.bottom, margin.top])

  var xAxis = g => g
    .style("font", "8px soehne")
    .attr("transform", `translate(0,${height - margin.bottom})`)
    .call(d3.axisBottom(x0).tickSize(5).tickSizeOuter(0))
    .call(g => g.selectAll(".tick line").remove())

  var yAxis = g => g
    .style("font", "8px soehne")
    .attr("transform", `translate(${margin.left},0)`)
    .call(d3.axisLeft(y).ticks(4).tickSize(-(width - margin.left - margin.right)).tickFormat((d, i) => i === 0 ? "" : d3.format("$,.2s")(d)))
    .call(g => g.selectAll(".tick line").attr("stroke", graphColors.tick))
    .call(g => g.selectAll(".domain").remove())

  var svg = d3.select(plotRef)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])

  svg.append("g")
    .call(yAxis)

  svg.append("g")
    .call(xAxis)
    .selectAll("text")  
    .style("text-anchor", "end")
    .attr("dx", "-.8em")
    .attr("dy", ".15em")
    .attr("transform", "rotate(-45)");

  var color = d3.scaleOrdinal()
    .domain(seriesNames)
    .range([graphColors.base, graphColors.baseTint2, graphColors.baseTint3, graphColors.baseTint4])

  svg.append("g")
    .selectAll("g")
    .data(data)
    .join("g")
    .attr("transform", d => "translate(" + x0(d.month) + ",0)")
    .selectAll("rect")
    .data(d => seriesNames.map(name => ({ name, value: d[name] })))
    .join("rect")
    .attr("x", d => x1(d.name))
    .attr("y", d => y(d.value))
    .attr("width", x1.bandwidth())
    .attr("height", d => height - margin.bottom - y(d.value))
    .attr("fill", d => color(d.name))
    .append("title")
    .text(d => `${d.name}: ${d3.format("$,.2s")(d.value)}`)

  const legend = svg.append("g")
    .selectAll("g")
    .data(seriesNames)
    .join("g")
    .attr("transform", (d, i) => "translate(" + i * (width / 5) + "," + (height - 20) + ")")
    .style("font", "8px soehne")

  legend.append("rect")
    .attr("x", 10)
    .attr("width", 10)
    .attr("height", 10)
    .attr("fill", color);

  legend.append("text")
    .attr("x", 24)
    .attr("y", 5)
    .attr("dy", "0.32em")
    .text(d => d);
};