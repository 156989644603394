const colors = {

  // Orange / Golden Bay
  base: "#ff8700",
  baseTint1: "#ff9f32",
  baseTint2: "#ffb766",
  baseTint3: "#ffcf99",
  baseTint4: "#ffe7cc",

  // Brown / Whenua
  secondary: "#291d00",
  secondaryTint1: "#534a32",
  secondaryTint2: "#7e7766",
  secondaryTint3: "#a9a499",
  secondaryTint4: "#d4d1cc",

  // Red / Ruby Bay:
  tertiary: "#ff2049",
  tertiaryTint1: "#ff4c6d",
  tertiaryTint2: "#ff7991",
  tertiaryTint3: "#ffa5b6",
  tertiaryTint4: "#ffd2da",

  greenShade: "#2b9913",

};

const { base, baseTint1, baseTint2, baseTint3, baseTint4, secondary, secondaryTint1, secondaryTint2, secondaryTint3, secondaryTint4, tertiary, tertiaryTint1, tertiaryTint2, tertiaryTint3, tertiaryTint4, greenShade } = colors;

export default { 
  ...colors,

  value: baseTint1,
  comparison: baseTint3,

  positive: greenShade,
  negative: tertiary,
  
  gain: baseTint3,
  loss: "white",

  target: base,

  text: secondary,

  tick: "#e9e8e5",

  donutBase: secondary,
  donutTint1: secondaryTint1,
  donutTint2: secondaryTint2,
  donutTint3: secondaryTint3,
  donutTint4: secondaryTint4,
  
}



